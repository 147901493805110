<template>
  <button
    class="whitespace-nowrap overflow-hidden relative transition-all py-2 px-4 font-semibold tracking-wider leading-none category-title mb-0 text-slate-200 cursor-pointer hover:text-white group"
    :class="[
      showUpdateCount && updateCount !== null && updateCount > 0 ? 'pl-2 pr-5 lg:pl-4 lg:pr-6' : 'px-2 lg:px-4',
      active ? 'text-white bg-slate-600 after:translate-y-0' : 'after:translate-y-3',
      size === 'small' ? 'text-xs sm:text-sm' : 'text-sm sm:text-base lg:text-base',
      lightlyRounded ? 'rounded' : 'rounded-lg'
    ]"
  >
    <div
      class="group-hover:text-white transition-all"
      :class="[
        active ? 'opacity-100 text-white' : 'text-slate-400',
        !active && flashing ? 'text-white !opacity-100 animate-jump-bounce' : '',
      ]"
    >
      <span>{{ text }}</span>
      <span v-if="showCount && count > 0" class="text-cyan"> ({{ count }})</span>
    </div>

    <div
      v-if="showUpdateCount && updateCount !== null && updateCount > 0"
      class="absolute w-5 h-5 top-3 lg:top-4 right-0 bg-blue-500 rounded-full flex justify-center items-center animate-fade-in overflow-hidden"
    >
      <transition :name="activityUpdateTransition" mode="out-in">
        <div
          :key="updateCount"
          class="text-white font-bold tracking-normal"
          :class="updateCount > 19 ? 'text-[10px]' : 'text-xs'"
        >
          {{ updateCount <= updateCountMax ? updateCount : updateCountMax }}
        </div>
      </transition>
    </div>
  </button>
</template>

<script setup>
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    default: null,
  },
  showCount: {
    type: Boolean,
    default: false,
  },
  count: {
    type: Number,
    default: null,
  },
  updateFlash: {
    type: Boolean,
    default: false,
  },
  updateCount: {
    type: Number,
    default: null,
  },
  showUpdateCount: {
    type: Boolean,
    default: false,
  },
  updateCountMax: {
    type: Number,
    default: null,
  },
  size: {
    type: String,
    default: null,
  },
  active: {
    type: Boolean,
    default: false,
  },
  lightlyRounded: {
    type: Boolean,
    default: false,
  },
});

const updateFlashDebounce = ref(null);
const flashing = ref(false);

const activityUpdateTransition = computed(() => (props.updateCount > (props.updateCountMax + 1)) ? '' : 'slideup-fade');

watch(
  () => props.updateFlash,
  () => {
    flashing.value = true;
    updateFlashDebounce.value = setTimeout(() => {
      flashing.value = false;
    }, 5000);
  }
);
</script>
